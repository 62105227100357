import React from "react"
import Styled from "styled-components"
// import { useScript } from '@utils'

const CalendlyWrapper = Styled.div`
  /* & .Calendly {
    width: 100%;
    height: 700px;
  }
  & button {
    margin: 0px auto;
  } */
`

function Calendly() {
	// const [loadScript, setLoadScript] = React.useState(false)

	const id = `uprise-calendly-popup-widget`

	// React.useEffect(() => {
	//   if (!loadScript) {
	//     setLoadScript(true)
	//   }
	// }, [loadScript])

	// useScript('https://assets.calendly.com/assets/external/widget.js', {
	//   shouldLoad: loadScript,
	// })

	return (
		<CalendlyWrapper>
			<div className='Calendly' id={id} />
		</CalendlyWrapper>
	)
}

export default Calendly
