import Plugin from "./Plugin"
import PaperForm from "./PaperForm"
import Vimeo from "./Vimeo"
import Code from "./Code"
import BirdSeed from "./BirdSeed"
import Calendly from "./Calendly"

export default Plugin

export {PaperForm, Vimeo, Code, Calendly, BirdSeed}
