import React from "react"
import Styled from "styled-components"
import {logger} from "@utils"

const CodeWrapper = Styled.div`
  width: 100%;
	height: ${props => props.height ? props.height.trim() : '100%'};
  display: flex;
  justify-content: center;
`

function Code({blok}) {
	const {script, body, source, height} = blok

	React.useEffect(() => {
		if (source) {
			const script = document.createElement("script")
			document.body.appendChild(script)
			script.onload = () => {
				logger("Loaded")
			}
			script.src = source
		} else {
			const scriptContent = script.slice(8, -9)
			window.eval(scriptContent)
		}
	}, [source, script])

	return <CodeWrapper height={height} dangerouslySetInnerHTML={{__html: body}}></CodeWrapper>
}

export default Code
